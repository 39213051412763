import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro";
import Footer from "components/Footer";
import { SectionHeading } from "components/misc/Headings";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
} from "components/headers/light.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const PrivacyPolicy = ({ headingText = "Politique de confidentialité" }) => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about-us">À propos</NavLink>
      <NavLink href="#">Contactez-nous</NavLink>
      <NavLink href="#">Blog</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/hire-us">Recrutez-nous</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>

          <Text>
            <p>
              Notre site Web respecte votre vie privée et cherche à protéger vos données personnelles.
            </p>

            <p>
              La politique de confidentialité explique comment vos données personnelles sont collectées et utilisées (sous certaines conditions). Il mentionne également les mesures prises pour assurer la confidentialité de vos informations. Enfin, cette politique définit les options qui s'offrent à vous concernant la collecte et l'utilisation des données personnelles. Et en visitant le site directement ou via un autre site, vous acceptez les pratiques décrites dans cette politique.
            </p>

            <p>
              La protection de vos données est très importante pour nous. Par conséquent, votre nom et d’autres informations vous concernant sont utilisés conformément aux conditions décrites dans la politique de confidentialité. Nous collecterons des informations lorsque cela est nécessaire ou si elles sont directement liées à nos transactions avec vous.
            </p>

            <p>
              Nous conserverons vos données conformément à la loi ou aux fins pour lesquelles elles ont été collectées.
            </p>

            <p>
              Vous pouvez naviguer sur le site sans avoir besoin de fournir de données personnelles. Votre identité personnelle reste anonyme lors de votre visite sur le site et n'est divulguée que si vous disposez d'un compte personnel sur le site auquel vous accédez avec un nom d'utilisateur et un mot de passe.
            </p>

            <h2>Données que nous collectons:</h2>

            <p>
              Nous pouvons avoir besoin de collecter vos informations si vous souhaitez passer une commande pour un produit sur notre site Web.
            </p>

            <p>
              Nous collectons, stockons et traitons vos données nécessaires pour suivre vos achats sur notre site Web, sécuriser toute réclamation potentielle qui pourrait survenir ultérieurement et vous fournir nos services disponibles. Nous pouvons collecter des informations personnelles, y compris, mais sans s'y limiter, votre nom, votre sexe, votre date de naissance et votre adresse e-mail.
            </p>

            <h2>Autres utilisations de vos informations personnelles :</h2>

            <p>
              Nous utilisons les informations que vous fournissez pour traiter vos demandes, vous fournir les services et informations disponibles sur notre site Web que vous demandez. De plus, nous utiliserons les informations que vous fournissez pour gérer votre compte chez nous et vérifier les transactions financières que vous effectuez en ligne. Cela comprend des audits de téléchargement de données à partir du site Web, l'identification de l'identité des visiteurs du site, ainsi que le développement et la personnalisation des mises en page et/ou du contenu du site pour les utilisateurs. Nous effectuons diverses recherches liées à la composition de la population et envoyons à l'utilisateur des informations utiles ou demandées, telles que des informations sur les produits et services, à condition que vous ne vous opposiez pas à être contacté à ce sujet. La communication se fait par e-mail pour vous fournir quelques détails sur d'autres produits et services si vous le souhaitez. Si vous préférez ne recevoir aucune communication promotionnelle et marketing, veuillez vous désinscrire de cette option à tout moment.
            </p>

            <p>
              Nous pouvons être en mesure de stocker les détails liés à votre commande en cours sur notre site Web, mais nous ne pouvons pas les récupérer directement pour des raisons de sécurité. En vous connectant à votre compte sur le site, vous pourrez accéder aux informations et détails de vos achats que vous avez demandés ou que vous recevrez prochainement. Vous pouvez également gérer les détails de votre adresse. Vous devez également vous engager à traiter vos données personnelles avec la plus grande confidentialité et à ne pas les mettre à la disposition de tiers sans autorisation. Nous déclinons toute responsabilité découlant d'une mauvaise utilisation des mots de passe, sauf si cette mauvaise utilisation est due à notre erreur.
            </p>

            <p>
              Autres utilisations de vos informations personnelles Nous pouvons utiliser vos informations personnelles dans des enquêtes et des recherches marketing, en fonction de vos préférences, à des fins statistiques tout en garantissant une confidentialité totale. Vous avez le droit de vous rétracter à tout moment. Nous n’envoyons aucune réponse à des tiers. Votre adresse email n'est divulguée que si vous souhaitez participer à des concours. Nous conservons les réponses aux enquêtes entièrement séparées de votre adresse e-mail personnelle.
            </p>

            <p>
              Nous pouvons envoyer des informations sur nous, notre site Web ou nos autres sites Web, ainsi que nos produits, ventes, promotions, newsletters et autres contenus connexes provenant des filiales ou partenaires de notre groupe. Si vous ne souhaitez pas recevoir ces informations supplémentaires (ou une partie de celles-ci), veuillez cliquer sur le lien « se désabonner » dans tout e-mail qui vous est envoyé, et nous cesserons de vous envoyer ces informations dans un délai de sept jours ouvrables (hors samedi, Dimanches, fêtes nationales et religieuses) à compter de la réception de votre notification. Nous vous contacterons pour vous renseigner sur des informations peu claires.
            </p>

            <p>
              Nous pouvons utiliser certaines données, tout en garantissant une confidentialité totale sur le site, à d'autres fins telles que la vérification de la localisation des utilisateurs et le suivi de leurs visites sur le site ou des liens dans l'e-mail lorsqu'ils cliquent pour le recevoir. Fournir de telles données, dont la source est inconnue et ne permettant pas de déterminer votre réelle identité personnelle, à un tiers tel que des éditeurs par exemple. Toutefois, ces données ne permettent pas d’identifier votre personnalité (car elles ne sont pas précisées pour l’identité).
            </p>

            <h2>Concurrence :</h2>

            <p>
              Concernant toute concurrence, nous utilisons les données pour informer les gagnants et annoncer nos offres. Vous pouvez trouver plus de détails sur les conditions de participation à chaque concours individuellement.
            </p>

            <h2>Autres parties et liens vers des sites Web :</h2>

            <p>
              Nous pouvons transférer vos informations à d'autres sociétés de notre groupe ou à nos agents et sous-traitants pour nous aider dans les transactions liées à notre politique de confidentialité. Par exemple, nous pouvons compter sur un tiers pour nous aider à vous livrer des produits, à collecter vos paiements et à les utiliser à des fins statistiques et de recherche marketing ou pour assister l'équipe du service client. Nous pouvons être amenés à échanger des informations avec un tiers pour nous protéger contre la fraude et minimiser les risques de crédit. En cas de vente de notre société ou d’une partie de celle-ci, nous pourrons être amenés à transférer nos bases de données, incluant vos informations personnelles. Contrairement à ce qui est indiqué dans la politique de confidentialité, nous ne vendrons pas vos données personnelles ni ne les divulguerons à un tiers sans obtenir votre consentement préalable, sauf si cela est nécessaire aux fins énoncées dans cette politique de confidentialité, ou si la loi l'exige. Le site peut contenir des publicités de tiers ou des liens vers d'autres sites ou des cadres pour d'autres sites. Veuillez noter que nous ne sommes pas responsables de la politique de confidentialité d'un tiers ou du contenu de ces politiques appliquées sur d'autres sites, et nous ne sommes pas responsables des tiers auxquels vos données sont transférées conformément à la politique de confidentialité.
            </p>

            <h2>Cookies :</h2>

            <p>
              L'acceptation des cookies n'est pas une condition obligatoire pour visiter le site. Veuillez toutefois noter que les fonctions « panier » du site ne peuvent pas être utilisées et qu'aucune commande ne peut être passée sans l'activation des cookies. Les cookies sont de petits fichiers texte qui permettent à notre serveur d'identifier votre ordinateur en tant qu'utilisateur unique lorsque vous visitez des pages spécifiques du site. Votre navigateur stocke ces fichiers sur votre disque dur. Les cookies peuvent être utilisés pour déterminer votre adresse de protocole Internet (IP), ce qui vous fait gagner du temps lorsque vous visitez ou revenez sur le site. Nous utilisons des cookies pour garantir votre confort lors de votre navigation sur ce site (par exemple, pour mémoriser votre identité lorsque vous souhaitez modifier votre panier sans avoir à saisir à nouveau votre adresse e-mail), et non pour obtenir ou utiliser d'autres informations vous concernant (telles que à des fins de marketing ciblé).
            </p>

            <p>
              Vous pouvez configurer votre navigateur pour refuser les cookies, mais cela peut limiter votre utilisation du site. Soyez assuré que notre utilisation des cookies ne contient aucune information personnelle ou privée et est exempte de virus. Ce site utilise Google Analytics, un service fourni par Google pour analyser l'utilisation des pages Internet. Pour analyser la façon dont les utilisateurs utilisent le site, Google Analytics s'appuie sur des cookies, qui sont des fichiers texte placés sur votre ordinateur. Google transférera les informations générées par les cookies concernant votre utilisation de ce site (y compris votre adresse IP) vers des serveurs aux États-Unis, où elles seront stockées. Google utilisera ces informations pour évaluer votre utilisation du site, préparer des rapports pour les opérateurs du site sur leurs activités et fournir d'autres services liés à l'activité du site et à l'utilisation d'Internet. Google peut également transférer ces informations à des tiers lorsque la loi l'exige ou lorsqu'un tiers traite les informations pour le compte de Google. Google n'associe pas votre adresse IP à aucune autre donnée qu'elle détient. Vous pouvez refuser l'utilisation de cookies en sélectionnant les paramètres appropriés de votre navigateur, mais veuillez considérer que dans ce cas, vous ne pourrez peut-être pas utiliser pleinement les fonctions du site. En utilisant ce site, vous acceptez que Google utilise vos données comme détaillé ci-dessus et aux fins décrites ci-dessus.
            </p>

            <h2>Sécurité :</h2>

            <p>
              Nous employons des techniques et mesures de sécurité appropriées pour empêcher tout accès non autorisé ou illégal à vos informations, ainsi que pour empêcher leur perte ou leur destruction. Lorsque nous collectons des données via le site Web, nous stockons vos informations personnelles dans une base de données de serveur électronique sécurisée. Nous utilisons des systèmes de pare-feu sur nos serveurs. Lorsque nous collectons les détails des cartes de paiement électroniques, nous les protégeons par cryptage, tel que Secure Socket Layer (SSL). En conséquence, il est difficile pour tout intrus de déchiffrer vos informations, car nous ne pouvons pas garantir une protection à 100 %. Nous vous déconseillons fortement d'envoyer tous les détails de votre carte de crédit ou de débit lorsque vous communiquez avec nous par voie électronique et sans cryptage. Nous avons mis en place des garanties matérielles, électroniques et procédurales directes pour le processus de collecte, de stockage et de divulgation de vos informations. Nos mesures de sécurité nous obligent parfois à demander une preuve de votre identité avant de vous divulguer vos informations personnelles. Il est de votre responsabilité de protéger votre mot de passe et votre ordinateur contre toute utilisation non autorisée.
            </p>

          </Text>

        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

export default PrivacyPolicy;