import TwoColWithIllustration from "../TwoColWithIllustration";
import WebShoppingSiteIllustrationSrc from "images/web_shopping.svg";

export default () => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <TwoColWithIllustration
      subheading=""
      heading="Conception de boutiques en ligne."
      description="Obtenez votre propre boutique et faites passer votre activité commerciale au monde en ligne. Publiez un nombre illimité de produits sans frais sur vos profits."
      imageIllustrationSrc={WebShoppingSiteIllustrationSrc}
      textOnLeft={false}
    />
  );
};
