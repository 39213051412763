
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import GlobalStyles from "./styles/GlobalStyles";

// pages
import Main from "pages/Main";
import HireUs from "pages/HireUs";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import PrivacyPolicy from "pages/PrivacyPolicy";

function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/hire-us" element={<HireUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contat-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
