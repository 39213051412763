import tw from "twin.macro";
import { SectionHeading/*, Subheading as SubheadingBase*/ } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

// const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
// const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

// const SubmitButton = tw(PrimaryButtonBase)`inline-block bg-primary-500 text-gray-100 lg:ml-6 mt-6 lg:mt-0`

const HireUsForm = ({
    subheading = "Contact Us",
    heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    submitButtonText = "Contact Me",
    formAction = "#",
    formMethod = "get",
    textOnLeft = true,
}) => {
    return (
        <Container>
            <TextContent>
                {/* {subheading && <Subheading>{subheading}</Subheading>} */}
                <Heading>Comming soon ...</Heading>
                {/* <Description>{description}</Description>
                <Form action={formAction} method={formMethod}>
                    <Input type="email" name="email" placeholder="Your Email Address" />
                    <SubmitButton type="submit">{submitButtonText}</SubmitButton>
                </Form> */}
            </TextContent>
        </Container>
    );
}

export default HireUsForm;
