import tw from "twin.macro";
import styled from "styled-components";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../components/headers/light";

const StyledHeader = styled(Header)`
  ${tw`px-8 py-3 max-w-none bg-primary-700 fixed top-0 w-full z-10`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Navbare = () => {

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about-us">À propos</NavLink>
      <NavLink href="/contat-us">Contactez-nous</NavLink>
      <NavLink href="#">Blog</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/hire-us">Recrutez-nous</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <StyledHeader links={navLinks} />
  );
}

export default Navbare;