import HireUsForm from "components/forms/HireUsForm";
import Navbare from "components/Navbare";
import tw from "twin.macro";

const Container = tw.div`relative`;

const HireUs = () => {
    return (
        <Container>
            <Navbare />
            <HireUsForm />

        </Container>
    );
}

export default HireUs;
