import TwoColWithIllustration from "../TwoColWithIllustration";
import StoreManagementIllustrationSrc from "images/store_management.svg";

export default () => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <TwoColWithIllustration
      subheading=""
      heading="Gestion des boutiques en ligne."
      description="Si votre emploi du temps ne vous permet pas de gérer votre boutique en ligne, laissez-nous nous en charger ! Une équipe dédiée pour suivre et gérer votre boutique, de la mise en page et du design à l'ajout des produits."
      imageIllustrationSrc={StoreManagementIllustrationSrc}
      textOnLeft={true}
    />
  );
};
