import TwoColWithIllustration from "../TwoColWithIllustration";
import OnlineAdIllustrationSrc from "images/online_ad.svg";

export default () => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <TwoColWithIllustration
      subheading=""
      heading="Marketing digital"
      description="Vos ventes sont faibles ? Vous ne parvenez pas à atteindre vos clients ? Le service de marketing vous aide à résoudre ces problèmes en promouvant votre boutique sur toutes les plateformes."
      imageIllustrationSrc={OnlineAdIllustrationSrc}
      textOnLeft={false}
    />
  );
};
