import TwoColWithIllustration from "../TwoColWithIllustration";
import BuidingWebSiteIllustrationSrc from "images/building_websites.svg";

export default () => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <TwoColWithIllustration
      subheading=""
      heading="Création de sites Web."
      description="Presentez-vous de manière professionnelle à vos clients et aidez-les à accéder aux services de votre entreprise de manière plus rapide, efficace et distinctive par rapport à vos concurrents."
      imageIllustrationSrc={BuidingWebSiteIllustrationSrc}
      textOnLeft={true}
    />
  );
};
