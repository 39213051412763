import TwoColWithIllustration from "../TwoColWithIllustration";
import DesignToolsIllustrationSrc from "images/design_tools.svg";

export default () => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <TwoColWithIllustration
      subheading=""
      heading="Branding & Création"
      description="Le service de design personnalisée vise à vous aider à obtenir des images et des bannières élégantes et modernes à moindre coût."
      imageIllustrationSrc={DesignToolsIllustrationSrc}
      textOnLeft={true}
    />
  );
};
