
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import BackgroundAsImage from "../components/hero/BackgroundAsImage";
import WebDev from "components/sections/WebDev";
import EcomWebsite from "components/sections/EcomWebsite";
import StoreManagement from "components/sections/StoreManagement";
import DigitalMarketing from "components/sections/DigitalMarketing";
import BrandingCreation from "components/sections/BrandingCreation";
import Footer from "components/Footer";

function Main() {
    return (
        <AnimationRevealPage>
            <BackgroundAsImage />
            <WebDev />
            <EcomWebsite />
            <StoreManagement />
            <DigitalMarketing />
            <BrandingCreation />
            <Footer />
        </AnimationRevealPage>
    );
}

export default Main;
