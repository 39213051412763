import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Footer from "components/Footer";
import { SectionHeading } from "components/misc/Headings";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
} from "components/headers/light.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const AboutUs = ({ headingText = "À propos" }) => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about-us">À propos</NavLink>
      <NavLink href="/contat-us">Contactez-nous</NavLink>
      <NavLink href="#">Blog</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/hire-us">Recrutez-nous</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>

          <Text>
            <p>
              Web Sphere Agency, votre partenaire incontournable pour la transformation numérique, s'engage à vous propulser vers le succès dans le monde digital. Notre expertise couvre le développement web sur mesure, le marketing digital, le branding et la création d'infographies percutantes.
            </p>

            <p>
              Ce qui nous distingue, c'est notre dévouement envers la qualité. Chaque projet que nous entreprenons est façonné avec soin pour répondre à vos besoins spécifiques. Nous croyons fermement que chaque entreprise mérite une présence en ligne forte et mémorable.
            </p>

            <p>
              Rejoignez-nous dans cette aventure numérique et découvrez comment nous pouvons ensemble renforcer votre empreinte en ligne, créer une identité de marque captivante et atteindre de nouveaux sommets dans le monde numérique. Faites confiance à Web Sphere Agency pour faire briller votre entreprise en ligne.
            </p>
          </Text>

        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

export default AboutUs;