import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Footer from "components/Footer";
import { SectionHeading } from "components/misc/Headings";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
} from "components/headers/light.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  span {
    ${tw`text-xl font-bold mt-2`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const ContactUs = ({ headingText = "Contactez Nous" }) => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about-us">À propos</NavLink>
      <NavLink href="/contat-us">Contactez-nous</NavLink>
      <NavLink href="#">Blog</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/hire-us">Recrutez-nous</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>

          <Text>
            <h2>
              Pour nous contacter, veuillez utiliser les informations suivantes :
            </h2>

            <p>
              <span>Par téléphone :</span> 0559155791 / 0783007061
            </p>

            <p>
              <span >Par e-mail :</span> contact@wsagency-dz.com
            </p>

            <p>
              Nous serons heureux de communiquer avec vous et de répondre à vos questions.
            </p>
          </Text>

        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

export default ContactUs;